/*!
 * InsuranceClaims Project
 * Copyright Â© 2021 SarhSoft All rights reserved.
 * M.Farhat
 */


html {
  //overflow: auto !important;
}

@import "../dist/fonts/Kufi/stylesheet.css";
@import "../dist/fonts/Sans/stylesheet.css";

::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  //background: transparent;
  //background-color: var(--v-primary-base);
  //background-color: #6b6b6b;
  background-color: #3d3d3d;
  //background-color: #4a4a4a;
  border-radius: 0;
  //border: 1px solid #4a4a4a;
}

::-webkit-scrollbar-thumb {
  background: #daeef3;
  //background: var(--v-secondary-base);
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  //background: #4a4a4a;
  background: var(--v-secondary-base);
}

.w-100 {
  width: 100% !important;
}

$app-btn-margins: 10px !default;
.v-application--is-rtl .app-row > .app-btn {
  margin-left: $app-btn-margins;
}

.v-application--is-ltr .app-row > .app-btn {
  margin-right: $app-btn-margins;
}

.pointer {
  cursor: pointer !important;
}
